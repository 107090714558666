export const SaveStates = Object.freeze({
  Blank: null,
  Idle: 'idle',
  Loading: 'loading',
  Processing: 'processing',
  Success: 'success',
  Errored: 'errored',
  Info: 'info'
});


/**
 * This Should match the Permissions as defined on the API
 * see app/lib/afflo/permission_names.rb
 */
export const PermissionNames = Object.freeze({
  AREA_INTERNAL: 'area_internal',
  AREA_EXTERNAL: 'area_external',
  AREA_ADMIN: 'area_admin',
  INTERNAL_DASHBOARD_PLUGIN: 'internal_dashboard_plugin',
  INTERNAL_DASHBOARD_MANUAL: 'internal_dashboard_manual',
  INTERNAL_DASHBOARD_UNFINISHED_BUSINESS: 'internal_dashboard_unfinished_business',
  EXTERNAL_DASHBOARD_ACTIVE_OFFERS: 'external_dashboard_active_offers',
  EXTERNAL_DASHBOARD_ANALYTICS: 'external_dashboard_analytics',
  EXTERNAL_DASHBOARD_REPORTS: 'external_dashboard_reports',
  EXTERNAL_TRANSPLANT_CENTER_REPORTING: 'external_transplant_center_reporting',
  INTERNAL_MATCH_COMMUNICATION_TEMPLATES: 'internal_match_communication_templates',
  INTERNAL_MATCH_DECLINE_OFFER: 'internal_match_decline_offer',
  INTERNAL_MATCH_ARO_RULES: 'internal_match_aro_rules',
  INTERNAL_USER_MANAGE_ON_CALL: 'internal_user_manage_on_call',
  INTERNAL_USER_MANAGE_PROFILE: 'internal_user_manage_profile',
  ADMIN_USERS: 'admin_users',
  ADMIN_USERS_MASS_IMPORT: 'admin_users_mass_import',
  ADMIN_TEAMS: 'admin_teams',
  ADMIN_ROLES: 'admin_roles',
  ADMIN_TROUBLESHOOT_DONORS: 'admin_troubleshoot_donors',
  ADMIN_COMMUNICATION_TEMPLATES: 'admin_communication_templates',
  ADMIN_MEMBERSHIP_CATEGORIES: 'admin_membership_categories',
  ADMIN_ARO_RULES: 'admin_aro_rules',
  ADMIN_PROCEDURE_NOTES: 'admin_procedure_notes',
  ADMIN_OP_REPORT_MONTHLY_TXC_REPORTING: 'admin_op_report_monthly_txc_reporting',
  ADMIN_REPORTS_UPLOAD_QA: 'admin_reports_upload_qa',
  ADMIN_INTEGRATIONS_OMNILIFE_WORKFLOWS: 'admin_integrations_omnilife_workflows'
})

/**
 * This should match the ServicesLevels as defined in the API
 * see: app/models/service_level.rb
 */
export const ServiceLevels =  { 
  TRIAGE: { name: 'Triage', code: 'triage' },
  OFFER_HANDLING: { name: 'Offer Handling', code: 'offer_handling' },
  CASE_HANDLING: { name: 'Case Handling', code: 'case_handling' }
};
