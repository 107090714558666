import { defineStore } from 'pinia'
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP, APIBaseRoute} from "@/src/endpoints";
import { handleErrors, makeRequest } from "@/src/utils";

export const useUserStore = defineStore('user', {
  state: ()=> {
    return {
      // lookup data
      teams: [],
      roles: [],
      users: [],
      membershipCategories: []
    }
  },
  actions: {
    // Generic load data method
    loadEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, params).then(response => {
          this[key] = response.data;
          resolve();
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },
    // Load all users to store
    loadAllUsers() {
      return this.loadEntity(APIRoute(EP.admin.users.index), 'users');
    },
    // Load all teams to store
    loadTeams() {
      return this.loadEntity(APIRoute(EP.admin.teams.index), 'teams');
    },
    // Load all roles to store
    loadRoles() {
      return this.loadEntity(APIRoute(EP.admin.roles.index), 'roles');
    },
    // Load all roles to store
    loadMembershipCategories() {
      return this.loadEntity(APIRoute(EP.admin.membership_categories.index), 'membershipCategories');
    },
    // Load Permitted Login Mechanisms
    loadPermittedLoginMechanisms() {
      return this.loadEntity(APIRoute(EP.admin.users.permitted_login_mechanisms), 'permittedLoginMechanisms');
    },
    makeRequest(method, url, payload = null) {
      return new Promise((resolve, reject) => {
        method(url, payload).then(response => {
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      })
    },
    // Load Users
    loadUsers(params) {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.users.index), { params: params });
    },
    // Load User
    loadUser(id) {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.users.show, { id: id }));
    },
    // Create User
    createUser(payload) {
      return makeRequest(beApiClient.post, APIRoute(EP.admin.users.create), { user: payload});
    },
    // Update User
    updateUser(id, payload) {
      return makeRequest(beApiClient.put, APIRoute(EP.admin.users.update, { id: id }), { user: payload});
    },
    // Add User Membership
    addMembership(id, payload) {
      return makeRequest(beApiClient.post, APIRoute(EP.admin.users.add_teams_to_user, { user_id: id}), { user: payload });
    },
    // Delete User Membership
    deleteMembership(id, payload) {
      return makeRequest(beApiClient.put, APIRoute(EP.admin.users.delete_membership, { user_id: id}), { details: payload });
    },
    // Disable User
    disableUser(id) {
      return makeRequest(beApiClient.put, APIRoute(EP.admin.users.disable, { user_id: id }));
    },
    // Enable User
    enableUser(id) {
      return makeRequest(beApiClient.put, APIRoute(EP.admin.users.enable, { user_id: id }));
    },
    // Invite User
    inviteUser(id) {
      return makeRequest(beApiClient.put, APIRoute(EP.admin.users.invite, { user_id: id }));
    },
    loadTeamsMissingFromUser(id) {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.users.teams_missing_from_users, { user_id: id }));
    },
    // Update User Membership
    updateMembership(id,user_id, payload) {
      return makeRequest(beApiClient.put, APIRoute(EP.admin.users.update_membership, { user_id: user_id, id: id}), { details: payload });
    },
    // Load Team group availabilities
    loadTeamGroupAvailabilities() {
      return makeRequest(beApiClient.get, APIRoute(EP.current_user.team_group_availabilities.index));
    },
     // Load User Team groups
    loadUserTeamGroups() {
      return makeRequest(beApiClient.get, APIRoute(EP.current_user.team_groups.index));
    },
    // Create User TeamGroup Availabilities
    createUserTeamGroupAvailabilities(payload) {
      return makeRequest(beApiClient.post, APIRoute(EP.current_user.team_group_availabilities.create), { team_group_availabilities: payload});
    },
    // Update User TeamGroup Availabilities
    updateUserTeamGroupAvailabilities(payload) {
      return makeRequest(beApiClient.patch, APIRoute(EP.current_user.team_group_availabilities.update), { team_group_availabilities: payload});
    },
    // Load Current User
    loadCurrentUser() {
      return makeRequest(beApiClient.get, APIRoute(EP.current_user.show));
    },
    putOnCall(){
      return makeRequest(beApiClient.put, APIRoute(EP.current_user.on_call));
    },
    takeOffCall(){
      return makeRequest(beApiClient.put, APIRoute(EP.current_user.off_call));
    },
    addVirtualNumber(userId) {
      return makeRequest(beApiClient.put, APIRoute(EP.admin.users.request_number, { user_id: userId }));
    },
    removeVirtualNumber(userId) {
      return makeRequest(beApiClient.put, APIRoute(EP.admin.users.release_number, { user_id: userId }));
    }
  }
});
