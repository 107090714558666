<template>
  <CardSection :loading="isSavableStateLoading" :error="majorError">
    <template #header>
      My Contact Information {{ selectedUser ? `- ${selectedUser.full_name}` : '' }}
    </template>

    <template #body>
      <SubSection>
        <template #body>
          <VeeForm v-if="selectedUser" @submit="onSubmit">
            <div class="row">
              <TextInput v-model="selectedUser.full_name" name="full_name" label="Name"
                disabled
                col-style="form-group-5-column-large" />
            </div>
            <div class="row">
              <TextInput v-model="selectedUser.email" name="email" label="Email"
                disabled
                col-style="form-group-5-column-large" />
            </div>
            <div class="row">
              <template v-if="selectedUser.virtual_phone_number">
                <div class="col-12">
                  <div class="row">
                    <TextInput v-model="selectedUser.virtual_phone_number" name="virtual_phone_number" disabled
                      label="Virtual number" col-style="form-group-5-column-large" />
                    <div class="col-md-2">
                      <label aria-hidden="true" class="d-block">&nbsp;</label>
                      <button class="btn btn-secondary" title="Copy virtual number" @click.prevent="copyToClipboard(selectedUser.virtual_phone_number)">
                        <font-awesome-icon :icon="['fas', 'copy']" />
                      </button>
                      <template v-if="copied">
                        <span style="color: #155724;">Copied!</span>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="form-group form-group-5-column-large">
                  <label for="get_virtual_phone_number" style="display: block;">Virtual number</label>
                  <a id="get_virtual_phone_number" href="#" style="display: block;" @click.prevent="getNumber"> Get
                    a virtual number</a>
                </div>
              </template>
            </div>
            <div class="row">
              <TextInput v-model="selectedUser.phone_number" name="phone_number" label="My phone number"
                placeholder="+1 123-456-7890" rules="required" col-style="form-group-5-column-large" />
            </div>

            <ActionToolbar primary-button-text="Save" :component-state="currentState"
              :error-message="formError" :secondary-enabled="false" />
          </VeeForm>
        </template>
      </SubSection>
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import SubSection from "@/src/components/shared/SubSection.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { Form as VeeForm } from 'vee-validate';
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { useAppCoreStore } from "@/src/stores/app-core";
import { PermissionNames } from "@/src/enums";
import TextInput from "@/src/components/shared/FormComponents/TextInput.vue";
import { clipboardMixin } from "@/src/mixins/clipboardMixin";

export default {
  components: {
    CardSection,
    SubSection,
    ActionToolbar,
    VeeForm,
    TextInput
  },
  mixins: [savableStateMixin, clipboardMixin],
  setup() {
    const appStore = useAppCoreStore();

    return { appStore }
  },
  data() {
    return {
      records: [],
      selectedUser: null,
      copied: false
    }
  },
  async mounted() {
    if (!this.appStore.currentUser.canRead(PermissionNames.INTERNAL_USER_MANAGE_PROFILE)) {
      const error = 'Access forbidden. Please contact an administrator.'
      this.handleMajorError(error);
    } else {
      try {
        this.setSavableStateLoading();
        await this.appStore.loadCurrentUser();
        this.loadForm();
        this.setSavableStateIdle();
      } catch (error) {
        this.handleMajorError(error);
      }
    }
  },
  methods: {
    async loadForm() {
      this.selectedUser = this.appStore.currentUser.profileInfo;
    },
    async onSubmit() {
      this.setSavableStateProcessing();
      try {
        const payload = {
          phone_number: this.selectedUser.phone_number
        }
        await this.appStore.updateProfile(payload);
        this.setSavableStateSuccess();
      } catch (error) {
        this.handleFormError(error);
      }
    },
    async getNumber() {
      this.setSavableStateProcessing();
      try {
        await this.appStore.requestPhoneNumber();
        this.loadForm();
        this.setSavableStateSuccess();
      } catch (error) {
        this.handleFormError(error);
      }
    }
  }
}
</script>

